import { render, staticRenderFns } from "./butterfly3.vue?vue&type=template&id=c7266260&scoped=true&"
var script = {}
import style0 from "./butterfly3.vue?vue&type=style&index=0&id=c7266260&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7266260",
  null
  
)

export default component.exports